.education {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 30px;
}

.logoLine {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.logoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18vw;
  height: 9vw;
  max-width: 120px;
  max-height: 60px;

  background-color: white;
  padding: 10px 15px;
  border-radius: 15px;
  margin: 3px 3px;
  box-shadow: inset 0 0px 8px 0 rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

.logoBox:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.logoBoxSelected {
  margin: 0px 0px;
  border: 3px solid var(--fg2-color);
}

.logo {
  max-width: 100%;
  max-height: 100%;
}

.slider {
  width: 95vw;
  padding: 15px 0px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;

  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.slides::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.slides::-webkit-scrollbar-track {
  background: transparent;
}

.slides::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 3.5vw;
  padding-top: 25px;
}

.card {
  scroll-snap-align: center;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  width: 80vw;
  padding: 4vw;
  margin: 0px 0vw;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.schoolName {
  font-size: 1.4em;
  font-weight: bold;
  margin-right: 10px;
}

.location {
  font-size: 0.9em;
  color: white;
  background-color: var(--fg2-color);
  padding: 2px 5px;
  user-select: none;
}

.date {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 500;
  transition: all 1s ease-in;
}

.hide {
  opacity: 0;
}

.diplomaTitle {
  font-weight: 500;
}

.description {
  font-size: 0.8em;
  text-align: justify;
}

.technologiesWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.arrow {
  position: absolute;
  top: 50%;
  max-width: 30px;
  max-height: 30px;
  transition: filter 0.2s linear;
  border-radius: 50%;
  cursor: pointer;
  filter: brightness(30%);
}

.arrow:hover {
  filter: brightness(0%);
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.prevArrow {
  left: 30px;
  transform: translate(0, -50%);
}

.nextArrow {
  right: 30px;
  transform: translate(0, -50%) rotate(180deg);
}

@media (min-width: 900px) {
  .logoLine {
    width: 55vw;
  }

  .logoBox {
    width: 10vw;
    height: 5vw;

    padding: 15px 25px;
    border-radius: 20px;
  }

  .slider {
    width: 60vw;
    padding: 25px 35px;
  }

  .wrapper {
    padding: 25px 6vw;
  }

  .card {
    width: 45vw;
    padding: 1.5vw;
  }
}

@media (min-width: 1100px) {
  .logoLine {
    width: 600px;
  }

  .slider {
    width: 650px;
  }

  .wrapper {
    padding: 25px 60px;
  }

  .card {
    width: 500px;
    padding: 15px;
  }
}
