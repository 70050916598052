:root {
  --timeline-distance: 10vw;
}

.timeline {
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: 20vw;
  /* transform: translate(-50%, 0); */

  width: 8px;
  background-color: var(--light-text-color);
  transition-delay: var(--delay-close1);
  transition-duration: var(--duration-close1);
}

.timelineUnfold {
  transition-delay: var(--delay-open1);
  transition-duration: var(--duration-open1);
}

.timelineStart {
  background: linear-gradient(
    0deg,
    var(--light-text-color) 80%,
    var(--bg-color) 100%
  );
}

.timelineEnd {
  background: linear-gradient(
    180deg,
    var(--light-text-color) 80%,
    var(--bg-color) 100%
  );
}

.dateInfo {
  position: absolute;
  top: 34px;
  left: 20px;
  font-weight: 500;
  white-space: nowrap;
  /* color: white; */
  /* background-color: var(--fg-color); */
  /* padding: 2px 5px; */

  transition-delay: var(--delay-open2);
  transition-duration: var(--duration-open2);
}

.point {
  display: none;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  border: 4px solid var(--bg-color);
  background-color: var(--fg-color);

  top: 50%;
  transform: translate(0, -50%);
  left: -8px;
}

.dateBox {
  display: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -65px;
  background-color: var(--fg-color);

  align-items: center;
  justify-content: flex-end;
  width: 40px;
  height: 22px;
  user-select: none;
}

.arrow {
  position: absolute;
  top: 3px;
  left: 32px;
  padding: 8px;
  transform: rotate(-45deg);
  border-color: var(--fg-color);
  background-color: var(--fg-color);
}

.dateYear {
  padding-right: 1px;
  font-size: 0.9em;
  font-weight: 500;
  color: white;
  z-index: 1;
}

.duration {
  position: absolute;
  top: 50%;
  left: -140px;
  font-size: 0.9em;
  font-weight: 500;
  white-space: nowrap;
  color: white;
  background-color: var(--fg-color);
  padding: 2px 5px;
  user-select: none;

  transform: translate(0, -50%) rotate(-90deg);
  transition-delay: var(--delay-open2);
  transition-duration: var(--duration-open2);
}

.hide {
  opacity: 0;
  transition-delay: var(--delay-close2);
  transition-duration: var(--duration-close2);
}

@media (min-width: 900px) {
  .timeline {
    top: -20px;
    bottom: -20px;
    left: calc(-15vw + var(--timeline-distance));
  }

  .timelineUnfold {
    left: var(--timeline-distance);
  }

  .dateInfo {
    left: 68px;
  }

  .point {
    display: initial;
  }

  .dateBox {
    display: flex;
  }
}

@media (min-width: 1100px) {
  .timeline {
    left: -65px;
  }

  .timelineUnfold {
    left: 100px;
  }
}
