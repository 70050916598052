:root {
  --bg-color: #f9f7f7;
  --fg-color: #112d4e;
  --fg2-color: #3f72af;
  --dark-text-color: #0b1c31;
  --light-text-color: #dbe2ef;
  --incorrect-color: #af443f;

  --footer-height: 100px;
  --header-height: 70px;
}

/* || GENERAL STYLES */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif; /* Trebuchet MS */
  font-size: 14px;
  line-height: 1.3;
  color: var(--dark-text-color);
  overflow-y: scroll;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2em;
}

h2 {
  margin: 25px 0px;
}

header {
  font-size: 1.2em;
}

img {
  user-select: none;
}

@media (max-width: 360px) {
  body {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  body {
    font-size: 16px;
  }
}
