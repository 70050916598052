:root {
  --delay-open1: 0s;
  --duration-open1: 0.5s;
  --delay-open2: 0.3s;
  --duration-open2: 0.4s;
  --delay-close1: 0.2s;
  --duration-close1: 0.4s;
  --delay-close2: 0s;
  --duration-close2: 0.4s;
}

.position {
  position: relative;
}

.card {
  display: flex;
  align-items: center;
  width: 86vw;
  margin: 40px 0vw;
  padding: 10px 5vw;

  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  transition-delay: var(--delay-open1);
  transition-duration: var(--duration-open1);
  position: relative;
  z-index: 2;
}

.cardFold {
  width: 86vw;
  margin: 20px 0px;
  transition-delay: var(--delay-close1);
  transition-duration: var(--duration-close1);
}

.companyLogo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(15vw + 15px);
  height: calc(15vw + 15px);
  max-width: 180px;
  max-height: 180px;
  transition-delay: var(--delay-open2);
  transition-duration: var(--duration-open2);
}

.companyLogoFold {
  opacity: 0;
  transition-delay: var(--delay-close2);
  transition-duration: var(--duration-close2);
}

.logo {
  display: none;
  max-width: 80%;
  max-height: 60%;
}

.cardText {
  display: flex;
  flex-direction: column;
  /* margin-left: 20px; */

  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.cardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.companyName {
  font-size: 1.4em;
  font-weight: bold;
  margin-right: 10px;
}

.location {
  font-size: 0.9em;
  color: white;
  background-color: var(--fg2-color);
  padding: 2px 5px;
  user-select: none;
}

.arrow {
  width: 25px;
  height: 25px;
  margin-left: auto;
  transition: transform var(--duration-open1) linear var(--delay-open1);
  transform: rotate(90deg);
  filter: brightness(30%);

  cursor: pointer;
}

.arrow:hover {
  filter: brightness(0%);
}

.turnedArrow {
  transition-delay: var(--delay-close1);
  transition-duration: var(--duration-close1);
  transform: rotate(270deg);
}

.jobTitle {
  font-weight: 500;
}

.description {
  font-size: 0.8em;
}

.transition {
  opacity: 1;
  transition: opacity 0.3s linear, font-size var(--duration-open1) ease;
}

.hide {
  transition-delay: var(--delay-close1);
  font-size: 0em;
  opacity: 0;
  overflow: hidden;
}

@media (min-width: 900px) {
  .card {
    width: 65vw;
    max-width: 715px;
    margin: 40px 2vw;
    padding: 15px;
    padding-left: calc(15vw + 15px);
  }

  .cardFold {
    width: 50vw;
    min-width: 360px;
    max-width: 550px;
    margin: 20px 2vw;
    padding-left: 15px;
  }

  .logo {
    display: initial;
  }
}

@media (min-width: 1100px) {
  .card {
    padding-left: 180px;
  }

  .cardFold {
    padding-left: 15px;
  }
}
