.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  font-size: 2em;
  font-weight: 600;
}

.message {
  font-size: 1.8em;
  font-weight: 500;
}
