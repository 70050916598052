.app {
  position: relative;
  background-color: var(--bg-color);
  min-height: 100vh;
}

.app > div {
  min-height: calc(100vh - var(--footer-height) - var(--header-height));
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noNav > div {
  padding-top: 0;
}

.logo {
  width: 100px;
  height: 100px;
}
