.projectsPage {
  width: 100%;
  margin-top: 20px;
}

.background {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 100px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  clip-path: polygon(0 100px, 100% 0, 100% calc(100% - 100px), 0 100%);
  background-color: var(--fg-color);
  transition: height 0.3s linear 0s;
}

.extendedBackground {
  height: 450px;
}

.button {
  position: absolute;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease;

  font-weight: 500;
  color: var(---dark-text-color);
  background-color: var(--light-text-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.button:hover {
  background-color: white;
}

.hide {
  opacity: 0;
  cursor: initial;
}

.title {
  margin-top: 0px;
  margin-bottom: 18px;
  color: white;
  user-select: none;
}

.projectScreen {
  position: relative;
  width: 90vw;
  height: 80%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  box-shadow: inset 0 0px 14px 0px rgba(0, 0, 0, 0.8);
  transition: opacity 0.2s linear 0.2s;
}

.projectScreenHide {
  opacity: 0;
  width: 0px;
  height: 0px;
  z-index: -1;
  transition: opacity 0s linear 0s;
}

.menu {
  height: 100%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonMenu {
  width: 70%;
  height: 60px;
  margin: 24px 4px;
  border-radius: 20px;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.1s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.1em;
  font-weight: 600;
  color: var(--dark-text-color);
  border: 2px solid var(--bg-color);
  background-color: white;
  box-shadow: inset 0 0px 8px 0 rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

.buttonMenu:hover {
  border: 2px solid white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.buttonMenuSelected {
  border: 2px solid var(--fg-color);
}

.buttonMenuSelected:hover {
  border: 2px solid var(--fg-color);
}

.line {
  width: 1px;
  height: 70%;
  background-color: var(--fg-color);
}

.presentation {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.presentation > div {
  width: 85%;
  height: 75%;
  padding: 5%;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: auto;
}

.projectName {
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.projectTitle {
  font-weight: 500;
}

.description {
  font-size: 0.8em;
  text-align: justify;
}

.demo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.demo > span {
  padding: 10px 20px;
  text-align: center;
  border-radius: 20px;
  font-weight: 500;
  color: var(--light-text-color);
  background-color: var(--fg2-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  user-select: none;
  cursor: pointer;
}

.demo > span:hover {
  background-color: var(--fg-color);
}

.icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background-color: var(--fg2-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.icon:hover {
  background-color: var(--fg-color);
}

.logo {
  object-fit: cover;
  width: 18px;

  transition: color 0.3s ease;
}

.demoTV {
  cursor: pointer;
  border: 2px solid var(--fg-color);
}

.gif {
  object-fit: cover;
}

.next {
  margin: 5px;
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
  filter: brightness(30%);
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
  filter: brightness(30%);
}

.close:hover {
  filter: brightness(10%);
}

@media (min-width: 900px) {
  .background {
    padding: 140px 0px;
    clip-path: polygon(0 140px, 100% 0, 100% calc(100% - 140px), 0 100%);
  }

  .extendedBackground {
    height: 600px;
  }

  .title {
    margin-bottom: 35px;
  }

  .projectScreen {
    width: 60vw;
    height: 70%;
  }

  .presentation {
    width: 65%;
  }

  .presentation > div {
    width: 70%;
    height: 70%;
  }
}
