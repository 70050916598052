.body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.experiences {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.partTitle {
  margin-top: 0px;
}
