footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  color: var(--dark-text-color);
  background-color: var(--fg-color);

  display: grid;
  grid-template-columns: 1fr 50% 1fr;
}

.externLink {
  place-self: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.button {
  place-self: center;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  user-select: none;

  font-weight: 500;
  color: var(--dark-text-color);
  background-color: var(--light-text-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.button:hover {
  color: var(--fg-color);
  background-color: white;
}

.icon {
  place-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 5px;

  border-radius: 45px;
  background-color: var(--light-text-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.icon:hover {
  background-color: white;
}

.logo {
  object-fit: cover;
  width: 15px;

  filter: brightness(20%);
  transition: color 0.3s ease;
}

@media (min-width: 900px) {
  .icon {
    width: 45px;
    height: 45px;
    margin: 10px;
  }

  .logo {
    width: 18px;
  }
}
