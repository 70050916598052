.iconsList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 6px;
  width: 25px;
  height: 25px;
  position: relative;
}

.iconText {
  font-size: 0.7em;
  white-space: nowrap;
  padding: 2px 5px;
  border-radius: 20px;
  color: white;
  background-color: var(--fg2-color);

  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
  z-index: 1;
}

.iconContainer:hover .iconText {
  visibility: visible;
}

.icons {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.iconContainer:hover .icons {
  opacity: 0.5;
}

@media (max-width: 360px) {
  .iconContainer {
    margin: 3px 3px;
  }
}

@media (min-width: 900px) {
  .iconContainer {
    width: 30px;
    height: 30px;
  }

  .iconText {
    padding: 2px 5px;
    border-radius: 20px;

    top: 22px;
  }
}
