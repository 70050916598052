.background {
  position: relative;
  width: 100%;
  height: 600px;
  padding-bottom: 15vh;
  margin-bottom: 15vh;

  display: flex;
  justify-content: space-around;
}

.figure {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--fg-color);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
  z-index: 0;
}

.intro {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0px;
  width: 100%;
  height: 90%;
  z-index: 1;
}

.presentation {
  display: flex;
  height: 80%;
  justify-content: space-around;
  flex-direction: column;
  color: var(--light-text-color);
}

.name {
  font-weight: bold;
  font-size: 2.5em;
  margin: 0;
}

.qualifier {
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.6em;
}

.iconList {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.icon {
  place-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  border-radius: 100%;
  background-color: var(--light-text-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.icon:hover {
  background-color: white;
}

.logo {
  object-fit: cover;
  width: 15px;

  transition: color 0.3s ease;
  filter: brightness(20%);
}

.portrait {
  width: 55vw;
  max-width: 180px;
  height: auto;
  border-radius: 10%;
}

.subhead {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70vw;
  min-height: 20vh;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.subtitle {
  font-weight: 600;
  font-size: 1.6em;
}

.description {
  font-weight: 400;
  /* letter-spacing: 1.2px; */
  /* line-height: 1.5; */
  margin: 0;
  text-align: justify;
  font-size: 1.2em;
  line-height: 1.6;
}

@media (min-width: 600px) and (max-height: 750px) {
  .background {
    height: 70vh;
    align-items: center;
  }

  .subhead {
    top: 400px;
    top: calc(70vh - 100px);
  }
}

@media (min-width: 600px) {
  .intro {
    flex-direction: row;
    height: 70%;
  }

  .portrait {
    width: 20vw;
    max-width: 300px;
  }

  .icon {
    width: 45px;
    height: 45px;
  }

  .logo {
    width: 18px;
  }

  .subhead {
    width: 45vw;
  }
}
