.header {
  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: var(--header-height);
  background-color: var(--fg-color);
  user-select: none;
}

.name {
  margin: 0;
  color: var(--light-text-color);
}

.langMenu {
  position: fixed;
  z-index: 3;
  top: 0px;
  right: 2vw;

  transition: top 0.3s ease;
  user-select: none;
}

.langSelector {
  width: 80px;
  height: 35px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: var(--light-text-color);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 2px;
  transition: background-color 0.2s ease;
}

.langLogo {
  width: 22px;
  height: 18px;

  cursor: pointer;
  opacity: 0.9;
}

.langLogo:hover {
  filter: brightness(120%);
}

.darkLangSelector {
  background-color: #2c2d30;
}

.banner > div {
  position: absolute;
  top: 45px;
  left: 21px;

  font-size: 0.8em;
  font-weight: 600;
}

.banner > img {
  position: absolute;
  top: 36px;
  left: 16px;

  width: 28px;
  height: auto;
  transition: filter 0.2s ease;
}

.darkBanner > div {
  color: var(--light-text-color);
}

.darkBanner > img {
  filter: brightness(20%);
}

@media (max-width: 360px) {
  .banner > div {
    left: 22px;
  }
}

@media (min-width: 900px) {
  .langMenu {
    font-size: 16px;
    /* left: 88vw; */
  }

  .langSelector {
    width: 100px;
    height: 40px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .langLogo {
    width: 26px;
    height: 20px;
  }

  .banner > div {
    top: 50px;
    left: 26px;
  }

  .banner > img {
    top: 40px;
    left: 20px;

    width: 30px;
  }
}
