.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 600;
  font-size: 1.6em;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  padding: 15px;
  margin: 30px 0px;

  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.requiredMessage {
  width: 70vw;
  font-size: 0.9em;
  margin: 12px 0px;
}

.submit {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;

  color: var(--light-text-color);
  background-color: var(--fg2-color);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.submit:hover {
  background-color: var(--fg-color);
}

@media (min-width: 900px) {
  .form {
    width: 50vw;
    padding: 20px;
    margin: 40px;
  }

  .requiredMessage {
    width: 40vw;
  }
}
